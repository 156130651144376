import React from "react";
import { GrLocation } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";
import { FiTwitter, FiClock } from "react-icons/fi";
import { TfiPinterest } from "react-icons/tfi";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="max-w-[1320px] mx-auto py-10 md:px-12 md:py-12 bg-[#8ff2ba] text-gray-800">
            <div className="grid gap-10 md:grid-cols-4">
                {/* Location Section */}
                <div className="flex flex-col items-center text-center">
                    <GrLocation size={25} className="mb-4 text-gray-600" />
                    <h5>33/A8, A9,</h5>
                    <h5>Sree Devi Complex,</h5>
                    <h5>Vijaya Mahal Near,</h5>
                    <h5>Mecheri, Mettur (tk),</h5>
                    <h5 className="mb-5">Salem, TamilNadu - 636453</h5>
                    <div className="flex space-x-4">
                        <FaFacebookF className="hover:text-blue-600 cursor-pointer" size={25} />
                        <FiTwitter className="hover:text-blue-400 cursor-pointer" size={25} />
                        <TfiPinterest className="hover:text-red-600 cursor-pointer" size={25} />
                    </div>
                </div>

                {/* Working Hours Section */}
                <div className="flex flex-col items-center text-center">
                    <FiClock size={25} className="mb-4 text-gray-600" />
                    <div>
                        <h5>Mon-Fri: 9am-10pm</h5>
                        <h5>Sat: 10pm-4am</h5>
                        <h5>Sun: 10am-4pm</h5>
                    </div>
                </div>

                {/* Menu Section */}
                <div className="flex flex-col items-center text-center">
                    <p className="font-bold mb-4 text-gray-700">MENU</p>
                    <div className="flex flex-col space-y-2">
                        <h5 className="hover:text-blue-500 cursor-pointer">Home</h5>
                        <h5 className="hover:text-blue-500 cursor-pointer">Order</h5>
                        <h5 className="hover:text-blue-500 cursor-pointer">Wallets</h5>
                        <h5 className="hover:text-blue-500 cursor-pointer">Special Offer</h5>
                        <h5 className="hover:text-blue-500 cursor-pointer">Festival Offer</h5>
                    </div>
                </div>

                {/* Help Section */}
                <div className="flex flex-col items-center text-center">
                    <p className="font-bold mb-4 text-gray-700">HELP</p>
                    <div className="flex flex-col space-y-2">
                        <h5 className="hover:text-blue-500 cursor-pointer">Privacy Policy</h5>
                        <h5 className="hover:text-blue-500 cursor-pointer">Terms of Service</h5>
                        <h5 className="hover:text-blue-500 cursor-pointer">Customer Service</h5>
                        <h5 className="hover:text-blue-500 cursor-pointer">Self Service</h5>
                        <h5 className="hover:text-blue-500 cursor-pointer">About</h5>
                        <h5 className="hover:text-blue-500 cursor-pointer">Contact</h5>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
