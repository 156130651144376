import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Profile = () => {
    const [activeTab, setActiveTab] = useState('mobile'); // Default to 'mobile' tab
    const [showProfile, setShowProfile] = useState(false); // State to manage profile details visibility
    const navigate = useNavigate(); // Initialize navigate

    // Example profile data
    const profileData = {
        mobileNumber: '123-456-7890',
        email: 'example@example.com',
        address: {
            dNo: '123',
            street: 'Main Street',
            addressLine1: 'Apt 4B',
            addressLine2: '',
            postCode: '12345',
            city: 'Metropolis',
            district: 'Central',
            landmark: 'Near Central Park',
        }
    };

    return (
        <div className="max-w-[900px] mx-auto p-4">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-3xl font-bold">Profile Settings</h1>
                <button
                    className="bg-gray-300 py-2 px-4 rounded text-black hover:bg-gray-400"
                    onClick={() => navigate(-1)} // Go back to the previous page
                >
                    Back
                </button>
            </div>
            
            <div className="flex justify-between mb-8">
                <button 
                    className={`py-2 px-4 ${activeTab === 'mobile' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                    onClick={() => setActiveTab('mobile')}
                >
                    Update Mobile Number
                </button>
                <button 
                    className={`py-2 px-4 ${activeTab === 'address' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                    onClick={() => setActiveTab('address')}
                >
                    Update Address
                </button>
                <button 
                    className={`py-2 px-4 ${activeTab === 'view' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                    onClick={() => {
                        setActiveTab('view');
                        setShowProfile(!showProfile);
                    }}
                >
                    {showProfile ? 'Hide Profile' : 'View Profile'}
                </button>
            </div>

            {activeTab === 'mobile' && (
                <form className="space-y-4">
                    <div>
                        <label className="block text-lg font-medium mb-2">New Mobile Number</label>
                        <input 
                            type="text" 
                            placeholder="Enter new mobile number" 
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">New Email</label>
                        <input 
                            type="text" 
                            placeholder="Enter new email" 
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <button 
                        type="submit" 
                        className="w-full bg-blue-500 text-white py-2 rounded"
                    >
                        Update Mobile Number
                    </button>
                </form>
            )}

            {activeTab === 'address' && (
                <form className="space-y-4">
                    <div>
                        <label className="block text-lg font-medium mb-2">D.No</label>
                        <input 
                            type="text" 
                            placeholder="Enter new address" 
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">Street Address</label>
                        <input 
                            type="text" 
                            placeholder="Enter new address" 
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">Address Line1</label>
                        <input 
                            type="text" 
                            placeholder="Enter new address" 
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">Address Line2</label>
                        <input 
                            type="text" 
                            placeholder="Enter new address" 
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">Post Code</label>
                        <input 
                            type="text" 
                            placeholder="Enter new address" 
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">City</label>
                        <input 
                            type="text" 
                            placeholder="Enter new address" 
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">District</label>
                        <input 
                            type="text" 
                            placeholder="Enter new address" 
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">Landmark</label>
                        <input 
                            type="text" 
                            placeholder="Enter new address" 
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                    </div>
                    <button 
                        type="submit" 
                        className="w-full bg-blue-500 text-white py-2 rounded"
                    >
                        Update Address
                    </button>
                </form>
            )}

            {activeTab === 'view' && showProfile && (
                <div className="mt-8 p-4 border border-gray-300 rounded">
                    <h2 className="text-2xl font-bold mb-4">Profile Details</h2>
                    <p><strong>Mobile Number:</strong> {profileData.mobileNumber}</p>
                    <p><strong>Email:</strong> {profileData.email}</p>
                    <h3 className="text-xl font-semibold mt-4">Address:</h3>
                    <p><strong>D.No:</strong> {profileData.address.dNo}</p>
                    <p><strong>Street Address:</strong> {profileData.address.street}</p>
                    <p><strong>Address Line1:</strong> {profileData.address.addressLine1}</p>
                    <p><strong>Address Line2:</strong> {profileData.address.addressLine2}</p>
                    <p><strong>Post Code:</strong> {profileData.address.postCode}</p>
                    <p><strong>City:</strong> {profileData.address.city}</p>
                    <p><strong>District:</strong> {profileData.address.district}</p>
                    <p><strong>Landmark:</strong> {profileData.address.landmark}</p>
                </div>
            )}
        </div>
    );
};

export default Profile;
