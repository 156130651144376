import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

const YourOrder = () => {
  const location = useLocation();
  const { order } = location.state || {};

  if (!order) {
    return <div>No order details available</div>;
  }

  return (
    <>
      <Navbar />
      <div className='max-w-[1320px] mx-auto p-3 md:p-10 my-10'>
        <h1 className='mb-5 text-center font-bold text-blue-700 text-4xl'>Your Order</h1>
        <div className='bg-gray-100 p-6 rounded-lg shadow-lg'>
          <h2 className='text-2xl font-bold mb-4'>Order Summary</h2>
          <p className='text-lg mb-2'><strong>Item:</strong> {order.name}</p>
          <p className='text-lg mb-2'><strong>Quantity:</strong> {order.quantity}</p>
          <p className='text-lg mb-2'><strong>Total Price:</strong> RS{order.totalPrice}</p>
          <p className='text-lg mb-2'><strong>Payment Method:</strong> {order.paymentMethod}</p>
          <p className='text-lg mb-2'><strong>Email:</strong> {order.email}</p>
          <p className='text-lg mb-2'><strong>Phone:</strong> {order.phone}</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default YourOrder;
