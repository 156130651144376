import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signinStep1 } from "../api/Api";
import Navbar from './Navbar';
import Footer from './Footer';
import toast, { Toaster } from "react-hot-toast";
import { ACCESS_TOKEN, ADMIN_TOKEN, USERNAMEWITHMAIL } from "../config/Config";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../contexts/StateContext";

const SignInPage = () => {
  const navigate = useNavigate();
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );
  const setRole = useContextSelector(
    StateContext,
    (state) => state.setRole
  );
  const [showLoading, setShowLoading] = useState(false);
  
  // ============= Initial State Start here =============
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");

  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState("");

  // ============= Event Handler Start here =============
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };
  // ============= Event Handler End here ===============
  
  const handleSignUp = (e) => {
    e.preventDefault();

    if (!email) {
      setErrEmail("Enter your email");
    }

    const loginRequest = {
      usernameOrEmail: email,
    };
    // ============== Getting the value ==============
    if (email) {
      signinStep1(loginRequest)
      .then((res) => {
        localStorage.setItem(
          ACCESS_TOKEN,
          res.accessToken ? res.accessToken : null
        );
        localStorage.setItem(USERNAMEWITHMAIL,email);
        setIsLoggedIn(true);
        let token = res.accessToken;
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        token = JSON.parse(window.atob(base64));
        
        if (token.exp <= Math.floor(Date.now() / 1000)) {
          localStorage.removeItem(ACCESS_TOKEN);
          localStorage.removeItem(ADMIN_TOKEN);
          isLoggedIn(false);
        }

        if (token.rol.length >= 1) {
          alert("token Roll"+JSON.stringify(token.rol[0]))
          setRole(token.rol[0]);
        }
        navigate("/")
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error(
            "Your username or password is incorrect. Please try again !"
          );
        } else {
          toast.error(error.message);
        }
        setShowLoading(false);
      });
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg">
          <h1 className="text-3xl font-extrabold text-gray-900">Sign In</h1>
          <form className="space-y-4" onSubmit={handleSignUp}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                onChange={handleEmail}
                value={email}
                placeholder="Enter your email"
                className={`mt-1 w-full px-4 py-2 border ${errEmail ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
              />
              {errEmail && (
                <p className="text-sm text-red-500 mt-1">{errEmail}</p>
              )}
            </div>
            <div>
              <button
                type="submit"
                className="w-full py-3 text-base font-medium text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Sign In
              </button>
            </div>
            <p className="text-sm text-center text-gray-600">
              Don't have an account?{" "}
              <Link to="/signup" className="font-medium text-blue-600 hover:underline">
                Sign up
              </Link>
            </p>
          </form>
        </div>
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            error: {
              duration: 6000,
              style: {
                background: "#FF4136",
                color: "#fff",
              },
            },
            success: {
              duration: 6000,
              style: {
                background: "#008000",
                color: "#fff",
              },
            },
          }}
        />
      </div>
      <Footer />
    </>
  );
};

export default SignInPage;
