import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { retrieveFoodFlyOrder } from "../../src/api/PupilApi"; // Adjust this path as necessary
import { IoCloudDownload } from "react-icons/io5";
import { USERNAMEWITHMAIL } from "../config/Config";
import "./grid.css";

function UserDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, email } = location.state || {}; // Get name and email from location state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    const userName = localStorage.getItem(USERNAMEWITHMAIL);
    retrieveFoodFlyOrder(userName)
      .then((res) => {
        toast.success(res.data.message);
        setData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message || "Sorry! Something went wrong. Please try again!");
        setLoading(false);
        console.error(error);
      });
  };

  const handleClickBack = () => {
    navigate("/#");
  };

  return (
    <div className="control-pane flex-1 m-5">
      <div className="control-section">
        <div className="flex items-center mb-3">
          <h2 className="name pr-3 font-medium">
            Name:<span className="bg-dark font-semibold"> {name}</span>
          </h2>
          <button
            className="select-none rounded-lg float-right bg-black text-white py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md transition-all hover:shadow-lg focus:opacity-[0.85] active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50"
            onClick={handleClickBack}
          >
            <span>Back</span>
          </button>
        </div>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order ID</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Cost</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Shipping Charge</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Shop Name</th> {/* New column */}
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product List</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data.map((order) => (
                  <tr key={order.order_id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{order.order_id}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.orderStatus}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.totalCost}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.shippingCharge}</td>

                    {/* Extract shop name from first product in productListFoodFly */}
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.productListFoodFly.length > 0 ? order.productListFoodFly[0].shopName : "No Shop Name"}
                    </td> {/* Display shop name */}

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.productListFoodFly.map((product) => (
                        <div key={product.id} className="flex items-center">
                          <img src={product.image} alt={product.name} className="w-10 h-10 inline-block" />
                          <span className="ml-2">{product.name}</span>
                        </div>
                      ))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <Link
                        to={{
                          pathname: "/viewProductList",
                        }}
                        state={{
                          name: name,
                          email: email,
                          productList: order.productListFoodFly,
                        }}
                      >
                        <button
                          className="px-2 text-sm border border-gray-300 bg-gray-50 hover:bg-gray-100 rounded-lg font-bold uppercase"
                          type="button"
                        >
                          <IoCloudDownload />
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserDashboard;
