import React from 'react';


const ContactAddress = () => {
  return (
    <div className="contact-container">
      <h1>Contact Information</h1>
      <address>
        <p>33/A8, A9, Sree Devi Complex,</p>
        <p>Vijaya Mahal Near,</p>
        <p>Mecheri, Mettur (tk),</p>
        <p>Salem, TamilNadu - 636453</p>
      </address>
    </div>
  );
};

export default ContactAddress;
